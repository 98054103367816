// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExpenseSideBar_sidebar__lekT2 {
    background-color: #e6f7ff;
    padding: 20px;
    width: 100%;
    height: 1000px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    box-sizing:border-box;
  }
  
  .ExpenseSideBar_title__fIlPx {
    margin-bottom: 20px;
    color: #333;
  }
  
  .ExpenseSideBar_expensesList__792Pi {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 2em;
    
  }
  
  .ExpenseSideBar_expenseItem__2vjgH {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .ExpenseSideBar_expenseCategory__ZXJXp {
    flex: 1 1;
    color: #333;
  }
  
  .ExpenseSideBar_expenseBarContainer__yBqL3 {
    border-radius: 30px;
    flex: 2 1;
    height: 20px;
    background-color: #b3e0f2;
    margin: 0 10px;
  }
  
  .ExpenseSideBar_expenseBar__k8pj\\+ {
    border-radius: 30px;
    height: 100%;
    background-color: #0078d7;
  }
  
  .ExpenseSideBar_expenseAmount__6\\+HCo {
    white-space: nowrap;
    color: #333;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ExpenseSideBar.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,WAAW;IACX,cAAc;IACd,4BAA4B;IAC5B,+BAA+B;IAC/B,qBAAqB;EACvB;;EAEA;IACE,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,gBAAgB;;EAElB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,SAAO;IACP,WAAW;EACb;;EAEA;IACE,mBAAmB;IACnB,SAAO;IACP,YAAY;IACZ,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB;IACnB,WAAW;EACb","sourcesContent":[".sidebar {\n    background-color: #e6f7ff;\n    padding: 20px;\n    width: 100%;\n    height: 1000px;\n    border-top-left-radius: 30px;\n    border-bottom-left-radius: 30px;\n    box-sizing:border-box;\n  }\n  \n  .title {\n    margin-bottom: 20px;\n    color: #333;\n  }\n  \n  .expensesList {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n    line-height: 2em;\n    \n  }\n  \n  .expenseItem {\n    display: flex;\n    align-items: center;\n    margin-bottom: 20px;\n  }\n  \n  .expenseCategory {\n    flex: 1;\n    color: #333;\n  }\n  \n  .expenseBarContainer {\n    border-radius: 30px;\n    flex: 2;\n    height: 20px;\n    background-color: #b3e0f2;\n    margin: 0 10px;\n  }\n  \n  .expenseBar {\n    border-radius: 30px;\n    height: 100%;\n    background-color: #0078d7;\n  }\n  \n  .expenseAmount {\n    white-space: nowrap;\n    color: #333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `ExpenseSideBar_sidebar__lekT2`,
	"title": `ExpenseSideBar_title__fIlPx`,
	"expensesList": `ExpenseSideBar_expensesList__792Pi`,
	"expenseItem": `ExpenseSideBar_expenseItem__2vjgH`,
	"expenseCategory": `ExpenseSideBar_expenseCategory__ZXJXp`,
	"expenseBarContainer": `ExpenseSideBar_expenseBarContainer__yBqL3`,
	"expenseBar": `ExpenseSideBar_expenseBar__k8pj+`,
	"expenseAmount": `ExpenseSideBar_expenseAmount__6+HCo`
};
export default ___CSS_LOADER_EXPORT___;
